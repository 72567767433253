import CancelButton from '../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../../../components/antd-components/main-buttons/save-button/SaveButton'
import './style.scss'
import useCustomNavigate from '../../../../../../hooks/useCustomNavigate'

function PageFooter(props) {
  const {saveButtonHandler} = props
  const navigate = useCustomNavigate()

  const cancelButtonHandler = () => {
    navigate('/prro-settings')
  }

  return (
    <div className="prroFooter_wrapper">
      <CancelButton className="footer_button" onClick={cancelButtonHandler} />
      <SaveButton className="footer_button" onClick={saveButtonHandler} />
    </div>
  )
}

export default PageFooter
