import {PageHeaderWithMobileMenu} from '../../../../../../components'
import AddButton from '../../../../../../components/antd-components/main-buttons/add-button/AddButton'
import useCustomNavigate from '../../../../../../hooks/useCustomNavigate'

function PrroSettingsPageHeader() {
  const navigate = useCustomNavigate()

  const addButtonHandler = () => {
    navigate('/prro-settings/add')
  }
  const headerActions = [<AddButton onClick={addButtonHandler} />]
  return (
    <PageHeaderWithMobileMenu
      className="prroSettings_header"
      title="PRRO Settings"
      extra={headerActions}
    />
  )
}

export default PrroSettingsPageHeader
