import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import {AppTable} from '../../../../../../../../components/antd-components/app-table'
import usePrroSettingsTable from '../../../../../../../../services/prro-settings/prroSettingsTableService'
import {
  prroSettingLastUpdateSelector,
  prroSettingsListSelector,
  prroSettingsTableSettingsSelector,
} from '../../../../../../../../redux/selectors/prroSettingsSelector'
import usePrroSettingsService from '../../../../../../../../services/prro-settings/prroSettingsService'

function PrroSettingsTable() {
  const {columns} = usePrroSettingsTable()
  const tableSettings = useSelector(prroSettingsTableSettingsSelector)
  const prroSettingsList = useSelector(prroSettingsListSelector)
  const lastUpdate = useSelector(prroSettingLastUpdateSelector)
  const {getPrroSettingsList} = usePrroSettingsService()

  useEffect(() => {
    getPrroSettingsList(tableSettings)
  }, [lastUpdate])

  return (
    <AppTable
      className="prroSettings_table"
      columns={columns}
      dataSource={prroSettingsList}
      setTableSettings={() => {}}
      tableSettings={tableSettings}
      showPagination={false} // pagination inactive now
    />
  )
}

export default PrroSettingsTable
