import moment from 'moment'
import * as currency from 'currency.js'
import {en, formattedDate, ru, ua} from '../constants'
import {
  englishLanguage,
  russianLanguage,
  ukraineLanguage,
} from '../locales/localeAntdComponent'
import './style.scss'
import {ImageComponent} from '../components/images'

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  }
  return (
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    'ua'
  )
}

export const getLanguage = () => {
  const navigatorLanguage = getNavigatorLanguage()
  if (navigatorLanguage.includes(en)) {
    return en
  }
  if (navigatorLanguage.includes(ru)) {
    return ru
  }
  return ua
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function filterByNestedItems(array, searchQuery) {
  return searchQuery
    ? array.reduce((acc, item) => {
        if (item.children?.length) {
          const filtered = filterByNestedItems(item.children, searchQuery)
          if (filtered.length) {
            return [...acc, {...item, children: filtered}]
          }
        }
        const {children, ...itemWithoutChildren} = item
        return item.name?.toLowerCase().includes(searchQuery.toLowerCase())
          ? [...acc, itemWithoutChildren]
          : acc
      }, [])
    : array
}

export function createNestedItems(items, id = null, link = 'parentId') {
  return items
    .filter((item) => item[link] === id)
    .map((item) => ({
      ...item,
      key: item.id,
      title: item.name,
      children: createNestedItems(items, item.id),
    }))
}

export const checkId = (item) => {
  const {id = null, ...rest} = item
  return id ? item : rest
}

export const checkNumber = (obj, exceptions = []) => {
  const checkedItem = {}
  Object.entries(obj).forEach(([key, value]) => {
    checkedItem[key] =
      Number.isNaN(Number(value)) || exceptions.includes(key)
        ? value
        : Number(value)
    return [key, value]
  })
  return checkedItem
}

export const getRandomId = () => Math.random().toString(16).replace('.', '')

export const checkPositiveNumber = (value) => {
  const convertNumberType = Number(value)
  if (convertNumberType < 0) {
    return convertNumberType * -1
  }
  return convertNumberType
}

export const changeSortDirectionTitle = (title) => {
  if (title === 'ascend') {
    return 'ASC'
  }
  if (title === 'descend') {
    return 'DESC'
  }
  if (title === 'ASC') {
    return 'ascend'
  }
  if (title === 'DESC') {
    return 'descend'
  }
  return null
}

export const localizationAntdComponent = (language) => {
  switch (language) {
    case 'ua':
      return ukraineLanguage

    case 'ru':
      return russianLanguage

    case 'en':
      return englishLanguage

    default:
      return null
  }
}

export const convertToFractionalNumber = (value, fractionDigits = 2) =>
  Number(value).toFixed(fractionDigits)

export const cutString = (string, index) => {
  if (string !== undefined) {
    if (string.length > index) {
      return `${string.substring(0, index)}...`
    }
  }
  return string
}

export const formatDate = (date) =>
  date !== null ? moment(date).format(formattedDate.DateWithTime) : null

export const getColumnSorter = (tableSettings, columnName) => {
  if (tableSettings?.s_field === columnName) {
    if (tableSettings?.s_direction === 'ASC') {
      return 'ascend'
    }
    if (tableSettings?.s_direction === 'DESC') {
      return 'descend'
    }
  }
  return undefined
}

export const setDate = (date) => {
  if (date) {
    return moment(date)
  }
  return null
}

export const AntdTreeSelectRecursiveValue = (nodes) => {
  if (!Array.isArray(nodes.children) || !nodes.children.length) {
    return {
      value: nodes.id,
      title: nodes.name,
    }
  }
  return {
    value: nodes.id,
    title: nodes.name,
    children: nodes.children.map((node) => AntdTreeSelectRecursiveValue(node)),
  }
}

export const submitAntdTableFilter = ({
  setTableSettings,
  tableSettings,
  valueTo,
  valueFrom,
  filterValueTo,
  filterValueFrom,
  confirm,
}) => {
  setTableSettings({
    ...tableSettings,
    [valueTo]: filterValueTo,
    [valueFrom]: filterValueFrom,
  })
  confirm()
}

export const createItemHeaderMenu = (array) =>
  array?.map((item) => ({
    key: getRandomId(),
    label: item,
  })) || []

export const currencyDivide = (divide, divider, precision = 2) =>
  currency(divide, {precision}).divide(currency(divider, {precision})).value

export const currencyMultiply = (multiply, multiplicand, precision = 2) =>
  currency(multiply, {precision}).multiply(currency(multiplicand, {precision}))
    .value

export function isIncludeSelectedFilter(
  allElementsArray,
  selectedElementsArray,
) {
  const getIdInFirstArray = allElementsArray?.map((item) => item.id)
  const getIdInSecondArray = selectedElementsArray?.map((id) => id)
  const includeId = getIdInFirstArray?.filter((element) =>
    getIdInSecondArray?.includes(element),
  )
  return !includeId?.length
}

export function isCheckComa(value, isValueString = false) {
  if (String(value).includes(',')) {
    const changeValue = value.replace(/,/, '.')
    return isValueString ? changeValue : Number(changeValue)
  }
  return isValueString ? value.toString() : Number(value)
}

export const cutFloatNumberWithoutRounded = (number) =>
  Math.floor(number * 100) / 100

export const typeData = (type) => {
  if (type === 'inventory') {
    return 'blue'
  }
  if (type === 'set') {
    return 'default'
  }
  if (type === 'write-off') {
    return 'red'
  }
  if (type === 'sale') {
    return 'green'
  }
  if (type === 'add') {
    return 'yellow'
  }
  if (type === 'move') {
    return 'purple'
  }
  return 'default'
}
export const scrollToAndFocus = ({ref, referenceInput, focus = false}) => {
  if (!ref.current) return
  ref.current.scrollIntoView({inline: 'center', behavior: 'smooth'})
  if (focus) {
    referenceInput?.current?.focus()
  }
}

export const getValueWithPrecision = ({value, precision = 2}) =>
  currency(value, {precision}).value

export const currencyDivideWithRounding = (
  divide,
  divider,
  precisionRounding = 2,
  precision = 2,
) =>
  currency(
    currency(divide, {precision}).divide(currency(divider, {precision})).value,
    {precisionRounding},
  ).value

export const currencyMultiplyWithRounding = (
  multiply,
  multiplicand,
  precisionRounding = 2,
  precision = 2,
) =>
  currency(
    currency(multiply, {precision}).multiply(
      currency(multiplicand, {precision}),
    ).value,
    {precisionRounding},
  ).value

export const currencyAddWithPrecision = (adding, added, precision = 6) =>
  currency(adding, {precision}).add(added, {
    precision,
  }).value

export const objectWithoutNullField = (object) =>
  Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null))

export const mergedSentReceivedArray = (array1, array2) => {
  const map = new Map()
  array1?.forEach((item) => map.set(item.itemId, item))
  array2?.forEach((item) =>
    map.set(item.itemId, {
      ...map.get(item.itemId),
      ...item,
      sentAmount: map.get(item.itemId)?.sentAmount
        ? map.get(item.itemId)?.sentAmount
        : 0,
    }),
  )
  return Array.from(map.values())
}

export const getFormValueByName = (name, form) => form.getFieldValue(name, form)
export const convertToBooleanByLength = (value, length) =>
  value?.length === length

export const restrictInputValueAfterDot = (value, opts) => {
  const {decimalPlaces = 6} = opts ?? {}
  const isValueString = typeof value === 'string' && value !== 'NaN'
  const isValueNumber = typeof value === 'number' && !Number.isNaN(value)
  const pattern = new RegExp(`\\.(\\d{1,${decimalPlaces}}).*`)
  if (isValueString) {
    return value.replace(/,/, '.').replace(pattern, '.$1')
  }
  if (isValueNumber) {
    return Number(value.toString().replace(/,/, '.').replace(pattern, '.$1'))
  }
  return typeof value === 'number' ? 0 : ''
}

export const restrictPriceValueAfterDot = (value, isValuesString) =>
  restrictInputValueAfterDot(value, {decimalPlaces: 2, isValuesString})

export function UpperCaseFirst(str) {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}

export const separateThousand = (value) => {
  const initialDataBeforeDot = value.split('.')[0]
  const initialDataAfterDot = value.split('.')[1]
  const formattedNumber = Number(initialDataBeforeDot).toLocaleString()
  if (initialDataAfterDot) {
    return `${formattedNumber}.${initialDataAfterDot}`
  }
  return formattedNumber
}
export const crossedOutText = (text) => (
  <span className="line_through_text">{text}</span>
)
export const renderStoreName = (store) => {
  if (!store?.name) {
    return ''
  }
  return store.deletedDate ? crossedOutText(store.name) : store.name
}

export const priceFormat = (value) => value.toFixed(2)

export const checkIsResponseSuccess = (statusCode) =>
  statusCode?.toString().startsWith('2')

export const getPaymentMethodIcon = ({isCash}) => {
  if (Array.isArray(isCash))
    return (
      <div>
        {isCash.includes(true) && (
          <ImageComponent
            src="/cashIcon.png"
            height={40}
            width={45}
            preview={false}
            alt="cash"
          />
        )}
        {isCash.includes(false) && (
          <ImageComponent
            src="/masterCardIcon.png"
            height={40}
            width={45}
            preview={false}
            alt="card"
          />
        )}
      </div>
    )

  return (
    <div>
      {isCash ? (
        <ImageComponent
          src="/cashIcon.png"
          height={40}
          width={45}
          preview={false}
          alt="cash"
        />
      ) : (
        <ImageComponent
          src="/masterCardIcon.png"
          height={40}
          width={45}
          preview={false}
          alt="card"
        />
      )}
    </div>
  )
}

export const filterAndRemoveFields = (tableSettings) => {
  const filteredObj = {}
  Object.entries(tableSettings).forEach(([key, value]) => {
    if (value !== null) {
      filteredObj[key] = value
    }
  })
  if (!tableSettings.s_direction) {
    delete filteredObj.s_field
  }
  return filteredObj
}

export const getTableColumnId = ({dataIndex, align, width}) => ({
  title: '#',
  dataIndex: dataIndex || 'id',
  align: align || 'center',
  key: 'id',
  width: width || '75px',
  render: (value) => value,
})

export const cropStringToFixedLength = (value, length) => {
  if (value.length > length) {
    return `${value.slice(0, length)}...`
  }
  return value
}

export const validateItemData = (item, productsList) => {
  const {itemId, itemType, amount} = item
  const isAmountPresent =
    (!Number.isNaN(Number(amount)) || amount?.length > 0) && Number(amount) > 0
  if (!itemId) {
    return false
  }
  if (itemType === 'raw' && isAmountPresent) {
    return true
  }
  const currentProduct = productsList.find((product) => product.id === itemId)
  if (currentProduct) {
    const {piece} = currentProduct
    const isAmountPiece = Number.isInteger(Number(amount))
    if (piece && !isAmountPiece) {
      return false
    }
    if (isAmountPresent) {
      return true
    }
  }
  return false
}

export const compareObjectValues = (keys, firstObj, secondObj) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (firstObj[key] !== secondObj[key]) return false
  }

  return true
}

export const formatUserName = (fullName) => {
  if (fullName) {
    const parts = fullName.trim().split(' ')
    if (parts.length === 1) {
      return fullName
    }
    if (parts.length === 2) {
      const [lastName, firstName] = parts
      return `${lastName} ${firstName[0]}.`
    }
    if (parts.length >= 3) {
      const [lastName, firstName, surname] = parts
      return `${lastName} ${firstName[0]}.${surname[0]}.`
    }
  }
  return ''
}

export const trimFormValues = (values) => {
  const trimmedValues = Object.keys(values).reduce((acc, key) => {
    acc[key] =
      typeof values[key] === 'string' ? values[key].trim() : values[key]
    return acc
  }, {})
  return trimmedValues
}
