import {useTranslation} from 'react-i18next'
import {Button, Form, Upload} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import {AutoCompleteComponent} from '../../../../../../../../../components/antd-components/form'
import './style.scss'
import ProviderFieldsValidation from './providerValidation'
import useAttachmentsService from '../../../../../../../../../services/attachments/attachmentsService'

function PrroKeyListItem(props) {
  const {
    providerSelectOptions,
    keyData,
    onProviderValueChange,
    deleteButtonHandler,
    onFileStatusChange,
    removeFileHandler,
    validateKeyHandler,
  } = props
  const {t} = useTranslation()
  const validation = ProviderFieldsValidation()
  const {uploadAttachment} = useAttachmentsService()
  const getFileListForCurrentUploader = ({providerFieldKey}) => {
    const currentFile = keyData?.provider?.fields?.find(
      (field) => field.key === providerFieldKey,
    )?.file
    return currentFile ? [currentFile] : []
  }

  const isItemDisabled = !keyData.id.toString().includes('local')

  const customRequestHandler = (props) => {
    const {onSuccess, onError, file, onProgress, field} = props
    const formDataFile = new FormData()
    formDataFile.append('file', file)
    uploadAttachment({
      file: formDataFile,
      fileClass: field.key,
      uploadHandlers: {onProgress, onSuccess, onError},
    })
  }

  return (
    <div
      className={`prroKeyListItem_wrapper${
        keyData?.isKeyValid ? ' valid' : ''
      }`}
    >
      <div className={`prroKey_body ${isItemDisabled ? 'disabled' : ''}`}>
        <AutoCompleteComponent
          labelAlign="left"
          rules={{...validation, required: true}}
          options={providerSelectOptions}
          name={`provider_${keyData.id}`}
          placeholder={t('Provider')}
          label={t('Provider')}
          onChange={(selectedValue) => {
            onProviderValueChange({
              id: keyData.id,
              providerType: selectedValue,
            })
          }}
        />
        {keyData?.provider?.fields?.map((field) => (
          <Form.Item
            required
            labelAlign="left"
            label={t(field.name)}
            className={`key_wrapper ${
              field.validationError ? 'fieldValidation_error' : ''
            }`}
          >
            <Upload
              className="prroKey_uploader"
              maxCount={1}
              fileList={getFileListForCurrentUploader({
                providerFieldKey: field.key,
              })}
              onRemove={(file) => {
                removeFileHandler({
                  file,
                  keyDataId: keyData.id,
                  fieldKey: field.key,
                })
              }}
              onChange={({file}) => {
                onFileStatusChange({
                  file,
                  keyDataId: keyData.id,
                  fieldKey: field.key,
                })
              }}
              customRequest={async (props) => {
                customRequestHandler({...props, field})
              }}
            >
              {!isItemDisabled && (
                <Button className="upload_button" icon={<UploadOutlined />}>
                  {t('Upload')}
                </Button>
              )}
            </Upload>
            {field.validationError && (
              <div className="ant-form-item-explain-error">
                {t('This field is required.')}
              </div>
            )}
          </Form.Item>
        ))}
      </div>
      <div className="prroKey_actions">
        <Button
          className="action_button"
          type="primary"
          onClick={() => {
            validateKeyHandler(keyData)
          }}
        >
          {t('Validate')}
        </Button>
        <Button
          type="primary"
          className="action_button"
          danger
          onClick={() => {
            deleteButtonHandler({id: keyData.id})
          }}
        >
          {t('Delete')}
        </Button>
      </div>
    </div>
  )
}

export default PrroKeyListItem
