import {Form, Modal, Spin} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import InputPasswordForm from '../../../../../../components/antd-components/form/input-form/InputPasswordForm'
import {isPrroSettingsLoadingSelector} from '../../../../../../redux/selectors/prroSettingsSelector'
import usePrroSettingsService from '../../../../../../services/prro-settings/prroSettingsService'
import {checkIsResponseSuccess} from '../../../../../../utils/helpers'
import notify from '../../../../../../components/antd-components/notification/notify'

function ValidatePrroKeyModal({
  isModalOpen,
  toggleModalOpen,
  dataForValidation,
  setKeysList,
  keysList,
  setDataForValidation,
}) {
  const [form] = useForm()
  const {t} = useTranslation()
  const {validatePrroKeyHandler} = usePrroSettingsService()
  const isLoading = useSelector(isPrroSettingsLoadingSelector)

  useEffect(
    () => () => {
      form.resetFields()
      setDataForValidation(null)
    },
    [],
  )

  const onFinishHandler = async ({password}) => {
    if (dataForValidation) {
      const {currentKeyId, ...correctData} = dataForValidation
      await validatePrroKeyHandler({...correctData, password}).then(
        (response) => {
          if (checkIsResponseSuccess(response.payload)) {
            const updatedKeysList = keysList.map((key) => {
              if (key.id === currentKeyId) {
                return {...key, isKeyValid: true}
              }
              return key
            })
            notify({
              message: t('SUCCESS'),
              type: 'success',
            })
            setKeysList(updatedKeysList)
            toggleModalOpen()
          }
        },
      )
    }
  }
  return (
    <Modal
      confirmLoading={isLoading}
      open={isModalOpen}
      destroyOnClose
      onCancel={toggleModalOpen}
      onOk={() => {
        form.submit()
      }}
      title={t('Enter the password for the key provided by your provider')}
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical" form={form} onFinish={onFinishHandler}>
          <InputPasswordForm
            name="password"
            label={t('Password')}
            rules={{required: true, message: t('This field is required')}}
          />
        </Form>
      </Spin>
    </Modal>
  )
}

export default ValidatePrroKeyModal
