import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const getPrroSettings = createAsyncThunk(
  'GET_PRRO_SETTINGS',

  async ({params = {}}, {rejectWithValue}) => {
    try {
      const response = await API.get('/prro-settings/backoffice', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const getPrroSettingThunk = createAsyncThunk(
  'GET_PRRO_SETTING',

  async ({settingId = {}}, {rejectWithValue}) => {
    try {
      const response = await API.get(`/prro-settings/${settingId}`)
      if (response?.data?.payload) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const createPrroSettings = createAsyncThunk(
  'CREATE_PRRO_SETTING',
  async (data, {rejectWithValue}) => {
    try {
      const response = await API.post('/prro-settings', data)
      if (response?.data?.payload) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const updatePrroSettingThunk = createAsyncThunk(
  'UPDATE_PRRO_SETTING',
  async ({id, data}, {rejectWithValue}) => {
    try {
      const response = await API.put(`/prro-settings/${id}`, data)
      if (response?.data?.payload) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const deletePrroSettingsThunk = createAsyncThunk(
  'DELETE_PRRO_SETTINGS',
  async (idList, {rejectWithValue}) => {
    try {
      const response = await API.delete(`/prro-settings?ids[]=${idList}`)
      if (response?.data?.statusCode === 200) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const getPrroKeysTypesThunk = createAsyncThunk(
  'GET_PRRO_KEYS_TYPES',

  async ({params = {}}, {rejectWithValue}) => {
    try {
      const response = await API.get('/prro-keys/types', {params})
      if (response?.data?.payload) {
        return response.data.payload
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const validatePrroKeyThunk = createAsyncThunk(
  'VALIDATE_PRRO_KEY',

  async ({data = {}}, {rejectWithValue}) => {
    try {
      const response = await API.post('/prro-keys/validation', data)
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        return response.data.statusCode
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)
