import PrroSettingsTable from './components/prroSettings-table/PrroSettingsTable'

function PageBody() {
  return (
    <div>
      <PrroSettingsTable />
    </div>
  )
}

export default PageBody
