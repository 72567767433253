export const prroSettingsListSelector = (state) =>
  state.prroSettings.prroSettingsList

export const isPrroSettingsLoadingSelector = (state) =>
  state.prroSettings.isLoading

export const prroSettingLastUpdateSelector = (state) =>
  state.prroSettings.lastUpdated

export const prroSettingsTableSettingsSelector = (state) =>
  state.prroSettings.tableSettings

export const selectedPrroSettingsSelector = (state) =>
  state.prroSettings.selectedPrroSetting

export const prroKeysTypesSelector = (state) =>
  state.prroSettings.commonPrroData.prroKeysTypes

export const isValidatePrroKeyModalOpenSelector = (state) =>
  state.prroSettings.isValidatePrroKeyModalOpen
