import {useForm} from 'antd/es/form/Form'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {Spin} from 'antd'
import PageHeader from './components/page-header/PageHeader'
import PageBody from './components/page-body/PageBody'
import PageFooter from './components/page-footer/PageFooter'
import './style.scss'
import {isPrroSettingsLoadingSelector} from '../../../../redux/selectors/prroSettingsSelector'

import usePrroSettingsService from '../../../../services/prro-settings/prroSettingsService'
import useStore from '../../../../services/storeServices/storeService'

function CreatePrroSettingsPage() {
  const [form] = useForm()
  const {prroSettingId} = useParams()
  const isPrroDataLoading = useSelector(isPrroSettingsLoadingSelector)
  const {resetSelectedPrroSetting, getPrroKeysTypes} = usePrroSettingsService()
  const [keysList, setKeysList] = useState([])
  const {getStoresData} = useStore()

  useEffect(() => {
    getStoresData()
    getPrroKeysTypes()
  }, [])

  useEffect(
    () => () => {
      resetSelectedPrroSetting()
    },
    [],
  )

  const saveButtonHandler = async () => {
    let keysErrorCount = 0
    let isMainFieldsContainValidationErrors = false

    const updatedKeysList = keysList.map((keyData) => {
      if (!keyData.id.toString().includes('local')) {
        return keyData
      }
      const updatedFieldsData = keyData.provider.fields?.map((field) => {
        const isFileExist = field.file && field.file.status === 'done'
        if (!isFileExist) {
          keysErrorCount += 1
        }
        return {...field, validationError: !isFileExist}
      })
      return {
        ...keyData,
        provider: {...keyData.provider, fields: updatedFieldsData},
      }
    })
    if (keysErrorCount) {
      setKeysList(updatedKeysList)
    }
    try {
      form.validateFields()
    } catch (error) {
      isMainFieldsContainValidationErrors = true
    }
    if (!keysErrorCount && !isMainFieldsContainValidationErrors) {
      form.submit()
    }
  }

  return (
    <div className="createPrroSettings_page">
      <Spin spinning={isPrroDataLoading}>
        <PageHeader prroSettingId={prroSettingId} />
        <PageBody
          form={form}
          prroSettingId={prroSettingId}
          keysList={keysList}
          setKeysList={setKeysList}
        />
        <PageFooter saveButtonHandler={saveButtonHandler} />
      </Spin>
    </div>
  )
}

export default CreatePrroSettingsPage
