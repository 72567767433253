import {useEffect} from 'react'
import PrroSettingsForm from './components/prroSetting-form/PrroSettingsForm'
import './style.scss'
import usePrroSettingsService from '../../../../../../services/prro-settings/prroSettingsService'

function PageBody(props) {
  const {form, prroSettingId, keysList, setKeysList} = props
  const {getPrroSettingById} = usePrroSettingsService()

  useEffect(() => {
    if (prroSettingId) {
      getPrroSettingById(prroSettingId)
    }
  }, [prroSettingId])

  return (
    <div className="prroBody_wrapper">
      <PrroSettingsForm
        form={form}
        keysList={keysList}
        setKeysList={setKeysList}
      />
    </div>
  )
}

export default PageBody
