import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

function ProviderFieldsValidation() {
  const {t} = useTranslation()
  const schema = yup.lazy((value) => {
    if (value !== undefined) {
      return yup.object().shape({
        [Object.keys(value).toString()]: yup
          .string()
          .nullable()
          .required(t('This field is required.')),
      })
    }
    return yup.mixed().nullable()
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default ProviderFieldsValidation
