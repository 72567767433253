import {useTranslation} from 'react-i18next'
import moment from 'moment/moment'
import {getTableColumnId, renderStoreName} from '../../utils/helpers'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import {formattedDate} from '../../constants'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'
import usePrroSettingsService from './prroSettingsService'
import useCustomNavigate from '../../hooks/useCustomNavigate'

const usePrroSettingsTable = () => {
  const {t} = useTranslation()
  const {confirmDelete} = usePrroSettingsService()
  const navigate = useCustomNavigate()

  const editButtonHandler = (settingId) => {
    navigate(`/prro-settings/edit/${settingId}`)
  }
  const defaultColumnWidth = 100
  const getColumns = () => [
    getTableColumnId({width: 50}),
    {
      title: t('Author'),
      dataIndex: 'createdBy',
      align: 'center',
      width: defaultColumnWidth,
      render: (_, record) => record.createdBy?.name,
    },
    {
      title: t('Create date'),
      dataIndex: 'createdDate',
      align: 'center',
      width: defaultColumnWidth,
      render: (_, record) =>
        moment(record.createdDate).format(formattedDate.DateWithTime),
    },
    {
      title: t('Editor'),
      dataIndex: 'updatedBy',
      align: 'center',
      width: defaultColumnWidth,
      render: (_, record) => record.updatedBy?.name,
    },
    {
      title: t('Update date'),
      dataIndex: 'updatedDate',
      align: 'center',
      width: defaultColumnWidth,
      render: (_, record) =>
        record.updatedDate !== null
          ? moment(record.updatedDate).format(formattedDate.DateWithTime)
          : null,
    },
    {
      title: t('Store'),
      dataIndex: 'storeid',
      align: 'center',
      width: defaultColumnWidth,
      render: (_, record) => renderStoreName(record.store),
    },
    {
      title: t('Local name'),
      dataIndex: 'localNamePrro',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('TIN'),
      dataIndex: 'tin',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('IPN'),
      dataIndex: 'ipn',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('Organization name'),
      dataIndex: 'orgName',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('POS address'),
      dataIndex: 'pointAddress',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('POS name'),
      dataIndex: 'pointName',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('Local number'),
      dataIndex: 'cashDeskNum',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('Fiscal number'),
      dataIndex: 'cashRegisterNum',
      align: 'center',
      width: defaultColumnWidth,
    },
    {
      title: t('Active'),
      dataIndex: 'isPrroActive',
      align: 'center',
      width: defaultColumnWidth,
      render: (_, record) => {
        const tagColor = record.isPrroActive ? 'green' : 'red'
        const tagText = record.isPrroActive ? t('Yes') : t('No')
        return (
          <TagsComponent color={tagColor} keys={record.id} tagText={tagText} />
        )
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      align: 'center',
      fixed: 'right',
      width: defaultColumnWidth,
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={() => {
              editButtonHandler(record.id)
            }}
          />
          <DeleteActionButton
            onClick={() => {
              confirmDelete([record.id])
            }}
          />
        </div>
      ),
    },
  ]

  return {columns: getColumns()}
}

export default usePrroSettingsTable
