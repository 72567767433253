import {createSlice} from '@reduxjs/toolkit'
import {
  createPrroSettings,
  deletePrroSettingsThunk,
  getPrroKeysTypesThunk,
  getPrroSettings,
  getPrroSettingThunk,
  updatePrroSettingThunk,
  validatePrroKeyThunk,
} from '../asyncServices/prroSettingsService'

export const prroSettingsInitialState = {
  prroSettingsList: [],
  totalCount: 0,
  selectedPrroSetting: undefined,
  commonPrroData: {
    prroKeysTypes: [],
  },
  lastUpdated: null,
  isLoading: false,
  isValidatePrroKeyModalOpen: false,
  tableSettings: {
    s_field: 'storeId',
    s_direction: 'DESC',
    page: 1,
    pageSize: 100,
  },
}

const prroSettingsSlice = createSlice({
  name: 'prroSettingsSlice',
  initialState: prroSettingsInitialState,
  reducers: {
    setTableSettings: (state, action) => {
      state.tableSettings = {
        ...state.tableSettings,
        ...action.payload.tableSettings,
      }
    },
    clearSelectedPrroSetting: (state) => {
      state.selectedPrroSetting = undefined
    },
    toggleValidatePrroKeyModalOpen: (state) => {
      state.isValidatePrroKeyModalOpen = !state.isValidatePrroKeyModalOpen
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPrroSettings.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createPrroSettings.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createPrroSettings.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updatePrroSettingThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updatePrroSettingThunk.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updatePrroSettingThunk.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getPrroSettings.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPrroSettings.fulfilled, (state, action) => {
      state.prroSettingsList = action.payload.prroSettings
      state.totalCount = action.payload.totalCount
      state.isLoading = false
    })
    builder.addCase(getPrroSettings.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(deletePrroSettingsThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deletePrroSettingsThunk.fulfilled, (state) => {
      state.isLoading = false
      state.lastUpdated = Date.now()
    })
    builder.addCase(deletePrroSettingsThunk.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getPrroSettingThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPrroSettingThunk.fulfilled, (state, action) => {
      state.isLoading = false
      state.selectedPrroSetting = action.payload
    })
    builder.addCase(getPrroSettingThunk.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getPrroKeysTypesThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPrroKeysTypesThunk.fulfilled, (state, action) => {
      state.isLoading = false
      state.commonPrroData.prroKeysTypes = action.payload.types
    })
    builder.addCase(getPrroKeysTypesThunk.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(validatePrroKeyThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(validatePrroKeyThunk.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(validatePrroKeyThunk.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const {
  setTableSettings,
  toggleValidatePrroKeyModalOpen,
  clearSelectedPrroSetting,
} = prroSettingsSlice.actions

export default prroSettingsSlice.reducer
