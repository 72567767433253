import {useTranslation} from 'react-i18next'
import {Form} from 'antd'
import {useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {getRandomId} from '../../../../../../../../../utils/helpers'
import PrroKeyListItem from './PrroKeyListItem'
import AddActionButton from '../../../../../../../../../components/antd-components/tableAction-buttons/add-button/AddActionButton'
import ValidatePrroKeyModal from '../../../../../modals/validatePrroKey-modal/ValidatePrroKeyModal'
import {isValidatePrroKeyModalOpenSelector} from '../../../../../../../../../redux/selectors/prroSettingsSelector'
import usePrroSettingsService from '../../../../../../../../../services/prro-settings/prroSettingsService'

function PrroKeysList(props) {
  const {keysList, setKeysList, prroKeysTypes, form} = props
  const {t} = useTranslation()
  const [dataForValidation, setDataForValidation] = useState(null)
  const {toggleValidatePrroKeyModalVisible} = usePrroSettingsService()
  const isValidatePrroKeyModalOpen = useSelector(
    isValidatePrroKeyModalOpenSelector,
  )

  const addButtonHandler = () => {
    const newKeyData = {
      id: `local_${getRandomId()}`,
      provider: {
        type: undefined,
        fields: [],
      },
    }
    setKeysList([...keysList, newKeyData])
  }
  const deleteButtonHandler = ({id}) => {
    const updatedKeysList = keysList.filter((keyData) => keyData.id !== id)
    setKeysList(updatedKeysList)
  }

  const onFileStatusChange = ({file, keyDataId, fieldKey}) => {
    if (file.status !== 'removed') {
      const updatedKeysList = keysList.map((keyData) => {
        if (keyData.id === keyDataId) {
          const updatedProviderFields = keyData.provider?.fields.map(
            (field) => {
              if (field.key === fieldKey) {
                return {...field, file, validationError: false}
              }
              return field
            },
          )
          return {
            ...keyData,
            isKeyValid: false,
            provider: {
              ...keyData.provider,
              fields: updatedProviderFields,
            },
          }
        }
        return keyData
      })
      setKeysList(updatedKeysList)
    }
  }

  const onProviderValueChange = ({id, providerType}) => {
    let providerFields
    if (providerType) {
      providerFields = prroKeysTypes.find(
        (provider) => provider.type === providerType,
      )?.fields
    }
    setKeysList(
      keysList.map((keyData) => {
        if (keyData.id === id) {
          return {
            ...keyData,
            provider: {
              type: providerType,
              fields: providerFields,
            },
          }
        }
        return keyData
      }),
    )
  }

  const providerSelectOptions = useMemo(() => {
    if (prroKeysTypes?.length) {
      return prroKeysTypes.map((providerItem) => ({
        name: providerItem.provider,
        id: providerItem.type,
      }))
    }
    return []
  }, [prroKeysTypes])

  const removeFileHandler = ({file, keyDataId, fieldKey}) => {
    const updatedKeysList = keysList.map((keyData) => {
      if (keyData.id === keyDataId) {
        const updatedProviderFields = keyData.provider?.fields.map((field) => {
          if (field.key === fieldKey) {
            return {...field, file: undefined, validationError: true}
          }
          return field
        })
        return {
          ...keyData,
          provider: {...keyData.provider, fields: updatedProviderFields},
        }
      }
      return keyData
    })
    setKeysList(updatedKeysList)
  }

  const validateKeyHandler = async (keyData) => {
    let isValid = true
    try {
      await form.validateFields()
    } catch (error) {
      const currentFieldName = `provider_${keyData.id}`
      const fieldWithErrors = error?.errorFields?.map((item) => item.name[0])
      const providerErrors = fieldWithErrors?.filter((field) =>
        field?.includes('provider'),
      )
      const isMainFieldsValid =
        fieldWithErrors?.length === providerErrors?.length
      const isCurrentFieldValid = providerErrors.includes(currentFieldName)

      if (!isMainFieldsValid && !isCurrentFieldValid) {
        isValid = false
      }
    }
    const correctFiles = keyData.provider?.fields
      ?.filter((field) => field?.file?.status === 'done')
      ?.map((field) => field.file)
    if (
      correctFiles?.length &&
      correctFiles?.length === keyData.provider?.fields?.length &&
      isValid
    ) {
      const data = {
        storeId: form.getFieldValue('storeId'),
        cashRegisterNum: Number(form.getFieldValue('cashRegisterNum')),
        files: correctFiles?.map((file) => file.response?.id),
        providerType: keyData.provider.type,
        currentKeyId: keyData.id,
      }
      setDataForValidation(data)
      toggleValidatePrroKeyModalVisible()
    } else {
      const updatedKeysList = keysList.map((key) => {
        if (key.id === keyData.id) {
          const updatedFields = key.provider?.fields?.map((field) => {
            if (field.file?.status === 'done') {
              return field
            }
            return {...field, validationError: true}
          })
          return {
            ...key,
            provider: {...key.provider, fields: updatedFields},
          }
        }
        return key
      })
      setKeysList(updatedKeysList)
    }
  }

  return (
    <div className="prroKeysList_wrapper">
      <Form.Item
        label={
          <div>
            <span>{t('Keys')}</span>
            <AddActionButton onClick={addButtonHandler} />
          </div>
        }
        className="keysField_wrapper"
      >
        {keysList?.map((keyData) => (
          <PrroKeyListItem
            onFileStatusChange={onFileStatusChange}
            providerSelectOptions={providerSelectOptions}
            onProviderValueChange={onProviderValueChange}
            keyData={keyData}
            deleteButtonHandler={deleteButtonHandler}
            removeFileHandler={removeFileHandler}
            validateKeyHandler={validateKeyHandler}
            keysList={keysList}
          />
        ))}
      </Form.Item>
      {isValidatePrroKeyModalOpen && (
        <ValidatePrroKeyModal
          keysList={keysList}
          setKeysList={setKeysList}
          dataForValidation={dataForValidation}
          setDataForValidation={setDataForValidation}
          isModalOpen={isValidatePrroKeyModalOpen}
          toggleModalOpen={toggleValidatePrroKeyModalVisible}
        />
      )}
    </div>
  )
}

export default PrroKeysList
