import {useDispatch} from 'react-redux'
import {postAttachment} from '../../redux/asyncServices/attachmentService'

function useAttachmentsService() {
  const dispatch = useDispatch()
  const uploadAttachment = ({file, fileClass, uploadHandlers}) => {
    dispatch(postAttachment({file, fileClass, uploadHandlers}))
  }
  return {uploadAttachment}
}

export default useAttachmentsService
