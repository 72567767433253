import {useSelector} from 'react-redux'
import {Spin} from 'antd'
import PrroSettingsPageHeader from './components/page-header/PrroSettingsPageHeader'
import PageBody from './components/page-body/PageBody'
import {isPrroSettingsLoadingSelector} from '../../../../redux/selectors/prroSettingsSelector'

function PrroSettingsPage() {
  const isLoading = useSelector(isPrroSettingsLoadingSelector)

  return (
    <div>
      <Spin spinning={isLoading}>
        <PrroSettingsPageHeader />
        <PageBody />
      </Spin>
    </div>
  )
}

export default PrroSettingsPage
