import {Col, Form, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {
  AutoCompleteComponent,
  InputForm,
  SwitchForm,
  TextAreaForm,
} from '../../../../../../../../components/antd-components/form'
import './style.scss'
import PrroSettingsFormValidation from './validation'
import PrroKeysList from './prroKeys-list/PrroKeysList'

import {stores} from '../../../../../../../../redux/selectors/storeSelector'
import usePrroSettingsService from '../../../../../../../../services/prro-settings/prroSettingsService'
import {
  prroKeysTypesSelector,
  selectedPrroSettingsSelector,
} from '../../../../../../../../redux/selectors/prroSettingsSelector'
import {trimFormValues} from '../../../../../../../../utils/helpers'

function PrroSettingsForm(props) {
  const {form, keysList, setKeysList} = props
  const {t} = useTranslation()
  const storesList = useSelector(stores)
  const {createPrroSetting, updatePrroSetting} = usePrroSettingsService()
  const selectedPrroSettings = useSelector(selectedPrroSettingsSelector)
  const prroKeysTypes = useSelector(prroKeysTypesSelector)
  const validation = PrroSettingsFormValidation()

  const storeOptions = useMemo(() => {
    if (!storesList) return []
    const isCurrentStoreExist = storesList.some(
      (store) => store.id === selectedPrroSettings?.storeId,
    )
    if (!selectedPrroSettings || isCurrentStoreExist) {
      return storesList
    }
    return [
      ...storesList,
      {
        id: selectedPrroSettings.storeId,
        name: selectedPrroSettings.store?.name,
      },
    ]
  }, [storesList, selectedPrroSettings])

  useEffect(() => {
    if (selectedPrroSettings) {
      // Set main form data
      form.setFieldsValue({
        ...selectedPrroSettings,
        cashDeskNum: selectedPrroSettings.cashDeskNum?.toString(),
      })
      // Set providers fields
      selectedPrroSettings.keys?.forEach((key) => {
        const providerFieldName = `provider_${key.id}`
        form.setFieldValue(providerFieldName, key.provider)
      })
      // Set keys state
      const keys = selectedPrroSettings.keys.map((key) => ({
        id: key.id,
        provider: {
          type: key.provider,
          fields: key.fileLinks?.map((fileLink) => ({
            name: prroKeysTypes
              .find((type) => type.type === key.provider)
              ?.fields?.find((field) => field.key === fileLink.file.class)
              ?.name,
            key: fileLink.file.class,
            file: {
              uid: fileLink.fileId,
              name: fileLink.file.fileName,
              status: 'done',
              response: fileLink.file,
            },
          })),
        },
      }))
      setKeysList(keys)
    } else {
      setKeysList([])
      form.resetFields()
    }
  }, [selectedPrroSettings])

  const onFinish = (formData) => {
    const correctData = trimFormValues(formData)
    const prroKeys = keysList.map((keyData) => {
      const files = keyData.provider.fields?.map(
        (field) => field?.file?.response?.id,
      )
      const correctId = keyData.id.toString().includes('local')
        ? undefined
        : keyData.id
      return {id: correctId, providerType: keyData.provider.type, files}
    })
    const data = {
      ...correctData,
      cashRegisterNum: Number(correctData.cashRegisterNum),
      cashDeskNum: Number(correctData.cashDeskNum),
      prroKeys,
    }
    if (!selectedPrroSettings) {
      createPrroSetting(data)
    } else {
      updatePrroSetting({id: selectedPrroSettings.id, data})
    }
  }

  return (
    <Form
      onFinish={onFinish}
      form={form}
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      className="prroSettings_form"
    >
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={8}>
          <AutoCompleteComponent
            options={storeOptions}
            name="storeId"
            placeholder={t('Store')}
            label={t('Store')}
            rules={{...validation, required: true}}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InputForm
            name="localNamePrro"
            label={t('Local name')}
            rules={{...validation, required: true}}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InputForm
            name="tin"
            label={t('TIN')}
            rules={{...validation, required: true}}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InputForm name="ipn" label={t('IPN')} rules={{...validation}} />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <TextAreaForm
            autoSize={{minRows: 2, maxRows: 2}}
            rules={{...validation, required: true}}
            placeholder={t('Organization name')}
            label={t('Organization name')}
            name="orgName"
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <TextAreaForm
            autoSize={{minRows: 2, maxRows: 2}}
            rules={{...validation, required: true}}
            placeholder={t('POS address')}
            label={t('POS address')}
            name="pointAddress"
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <TextAreaForm
            autoSize={{minRows: 2, maxRows: 2}}
            rules={{...validation, required: true}}
            placeholder={t('POS name')}
            label={t('POS name')}
            name="pointName"
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InputForm
            name="cashDeskNum"
            label={t('Local number')}
            rules={{...validation, required: true}}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <InputForm
            name="cashRegisterNum"
            label={t('Fiscal number')}
            rules={{...validation, required: true}}
            onChange={(value) => console.log(value)}
          />
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <SwitchForm initialValue label={t('Active')} name="isPrroActive" />
        </Col>
        <Col xs={24} sm={24} lg={24}>
          <PrroKeysList
            form={form}
            prroKeysTypes={prroKeysTypes}
            keysList={keysList}
            setKeysList={setKeysList}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default PrroSettingsForm
