import {useTranslation} from 'react-i18next'
import {PageHeaderWithMobileMenu} from '../../../../../../components'

function PageHeader(props) {
  const {prroSettingId} = props
  const {t} = useTranslation()

  const headerTitle = prroSettingId
    ? `${t('Edit PRRO Setting')} #${prroSettingId}`
    : 'Create PRRO Setting'

  return (
    <PageHeaderWithMobileMenu
      className="prroSettings_header"
      title={headerTitle}
    />
  )
}

export default PageHeader
