import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const postAttachment = createAsyncThunk(
  'POST_ATTACHMENT',
  async ({file, fileClass, uploadHandlers}) => {
    const {onProgress, onSuccess, onError} = uploadHandlers
    const content = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        onProgress(
          {percent: Math.floor((event.loaded / event.total) * 100)},
          file,
        )
      },
    }
    file.append('class', fileClass)
    try {
      const response = await API.post('/files', file, {...content})
      if (response?.data?.payload) {
        onSuccess(response?.data?.payload)
        return response.data.payload
      }
      throw new Error()
    } catch (error) {
      const uploadError = new Error(error?.message)
      onError(uploadError)
      console.log(error)
    }
    return null
  },
)
